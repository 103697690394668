<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Index</th>
          <th scope="col">Type</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="node in graphNodes" :key="node.id">
          <td
            v-if="!editMode || focusedEntity !== node.id"
            @dblclick="setMode(node)"
            class="list-index"
            @click="updateState(node)"
          >
            <p>{{ node.label }}</p>
          </td>
          <td v-if="editMode && focusedEntity === node.id">
            <form @submit.prevent="updateLabel(node)">
              <input
                type="text"
                placeholder="Enter the name of node"
                v-model="nodeLabel"
              />
            </form>
          </td>
          <td>
            <p>{{ node.tjType || "No Type" }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
export default {
  computed: {
    graphNodes() {
      // Map of sorted labels
      const nodesMap = this.$store.getters['gmodule/getExploreData'].nodes
        .map((node) => node.label)
        .sort();
      // use indexOf to find whether its position is greater than or less than;
      return this.$store.getters['gmodule/getExploreData'].nodes.sort(
        (a, b) => nodesMap.indexOf(a.label) - nodesMap.indexOf(b.label),
      );
    },
  },

  data() {
    return {
      nodeLabel: '',
      editMode: false,
      focusedEntity: null,
    };
  },

  methods: {
    setNodeLabel(label) {
      this.nodeLabel = label;
    },
    setMode(node) {
      this.focusedEntity = node.id;
      this.editMode = true;
    },
    async fireNode(node) {
      this.editMode = false;

      const wordObj = {
        word: node.label,
        label: node.label,
        type: 'string',
        lang: this.$store.state.locale.currentLocale,
      };
      // this.$store.commit('SET_CURRENT_ENTITY', wordObj);
      await this.$store.dispatch('setCurrentThought', {
        thoughtObject: wordObj,
        excludeCallList: ['gmodule/exploreGraphFetch'],
      });
    },

    async deleteNode(node) {
      await this.$store.dispatch('gmodule/deleteNodeFromData', node);
    },

    async updateLabel(node) {
      await this.$store.dispatch('gmodule/updateLabelFromData', {
        node,
        label: this.nodeLabel,
      });
      this.editMode = false;
    },
    async updateState(node) {
      if (
        this.$store.state.gmodule.edit_mode === 'explore'
        || this.$store.state.gmodule.edit_mode === 'looked_at'
      ) {
        await this.fireNode(node);
      } else if (this.$store.state.gmodule.edit_mode === 'remove') {
        await this.deleteNode(node);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-index {
  &:hover {
    cursor: pointer;
    background-color: #d4d4d466;
  }
}
</style>
